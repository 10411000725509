import { UserActionTypes } from "./user.types";

const INITIAL_STATE = {
  isUserPending: false,
  user: null,
  userError: null,

  geo: {},

  isMoneyPending: false,
  moneyError: null,

  isSendMoneyPending: false,
  sendMoneyError: null,

  isSendGiftPending: false,
  sendGiftError: null,

  isRegisterPending: false,
  registerError: null,

  isLoginViaOtpPending: false,
  loginViaOtpError: null,

  isForgotPasswordPending: false,
  forgotPasswordError: null,

  isResetPasswordPending: false,
  resetPasswordError: null,

  isResetVerifyPasswordPending: false,
  resetVerifyPasswordError: null,
};

const UserReducer = (state = INITIAL_STATE, action = {}) => {
  switch (action.type) {
    case UserActionTypes.GET_USER_PENDING:
      return {
        ...state,
        user: null,
        isUserPending: true,
      };

    case UserActionTypes.PATCH_USER_SUCCESS:
    case UserActionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        isUserPending: false,
        user: action.payload,
        userError: null,
      };

    case UserActionTypes.GET_USER_ERROR:
      return {
        ...state,
        user: null,
        isUserPending: false,
        userError: action.payload,
      };

    case UserActionTypes.GET_GEO:
      return {
        ...state,
        isGeoPending: false,
        geo: action.payload,
        geoError: null,
      };

    case UserActionTypes.LOGIN.USER_VIA_OTP_PENDING:
      return {
        ...state,
        user: null,
        isLoginViaOtpPending: true,
      };

    case UserActionTypes.LOGIN.USER_VIA_OTP_SUCCESS:
      return {
        ...state,
        isLoginViaOtpPending: false,
        loginViaOtpError: null,
      };

    case UserActionTypes.LOGIN.USER_VIA_OTP_ERROR:
      return {
        ...state,
        isLoginViaOtpPending: false,
        loginViaOtpError: action.payload,
      };

    case UserActionTypes.POST_MONEY_PENDING:
      return {
        ...state,
        isMoneyPending: true,
      };

    case UserActionTypes.POST_MONEY_SUCCESS:
      return {
        ...state,
        isMoneyPending: false,
        user: action.payload,
        moneyError: null,
      };

    case UserActionTypes.POST_MONEY_ERROR:
      return {
        ...state,
        isMoneyPending: false,
        moneyError: action.payload,
      };

    case UserActionTypes.SEND_MONEY_PENDING:
      return {
        ...state,
        isSendMoneyPending: true,
      };

    case UserActionTypes.SEND_MONEY_SUCCESS:
      return {
        ...state,
        isSendMoneyPending: false,
        user: action.payload,
        sendMoneyError: null,
      };

    case UserActionTypes.SEND_MONEY_ERROR:
      return {
        ...state,
        isSendMoneyPending: false,
        sendMoneyError: action.payload,
      };

    case UserActionTypes.SEND_GIFT_PENDING:
      return {
        ...state,
        isSendGiftPending: true,
        sendGiftError: null,
      };

    case UserActionTypes.SEND_GIFT_SUCCESS:
      return {
        ...state,
        isSendGiftPending: false,
        sendGiftError: null,
      };

    case UserActionTypes.SEND_GIFT_ERROR:
      return {
        ...state,
        isSendGiftPending: false,
        sendGiftError: action.payload,
      };

    case UserActionTypes.USER_REGISTRATION_PENDING:
      return {
        ...state,
        isRegisterPending: true,
      };

    case UserActionTypes.USER_REGISTRATION_SUCCESS:
      return {
        ...state,
        isRegisterPending: false,
        user: action.payload,
        registerError: null,
      };

    case UserActionTypes.USER_REGISTRATION_ERROR:
      return {
        ...state,
        isRegisterPending: false,
        registerError: action.payload,
      };

    case UserActionTypes.FORGOT_PASSWORD.PENDING:
      return {
        ...state,
        isForgotPasswordPending: true,
      };

    case UserActionTypes.FORGOT_PASSWORD.SUCCESS:
      return {
        ...state,
        isForgotPasswordPending: false,
        forgotPasswordError: null,
      };

    case UserActionTypes.FORGOT_PASSWORD.ERROR:
      return {
        ...state,
        isForgotPasswordPending: false,
        forgotPasswordError: action.payload,
      };

    case UserActionTypes.RESET_PASSWORD.PENDING:
      return {
        ...state,
        isResetPasswordPending: true,
      };

    case UserActionTypes.RESET_PASSWORD.SUCCESS:
      return {
        ...state,
        isResetPasswordPending: false,
        resetPasswordError: null,
      };

    case UserActionTypes.RESET_PASSWORD.ERROR:
      return {
        ...state,
        isResetPasswordPending: false,
        resetPasswordError: action.payload,
      };

    case UserActionTypes.RESET_VERIFY_PASSWORD.PENDING:
      return {
        ...state,
        isResetVerifyPasswordPending: true,
      };

    case UserActionTypes.RESET_VERIFY_PASSWORD.SUCCESS:
      return {
        ...state,
        isResetVerifyPasswordPending: false,
        resetVerifyPasswordError: null,
      };

    case UserActionTypes.RESET_VERIFY_PASSWORD.ERROR:
      return {
        ...state,
        isResetVerifyPasswordPending: false,
        resetVerifyPasswordError: action.payload,
      };

    default:
      return state;
  }
};

export default UserReducer;
