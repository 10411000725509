import { toast } from "react-toastify";

import { UserActionTypes } from "./user.types";
import { CartActionTypes } from "../cart/cart.types";
import { axiosInstance } from "../../axios.config";

export const getUser = () => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USER_PENDING });
  axiosInstance
    .get(`/user/me`)
    .then((res) => {
      dispatch({
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.GET_USER_ERROR,
        payload: error.response.data,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: 0,
      });
    });
};

export const updateUser = (data) => (dispatch) => {
  dispatch({ type: UserActionTypes.PATCH_USER_PENDING });
  axiosInstance
    .patch(`/user/me`, data)
    .then((res) => {
      dispatch({
        type: UserActionTypes.PATCH_USER_SUCCESS,
        payload: res.data.result,
      });
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.PATCH_USER_ERROR,
        payload: error.response.data,
      });
    });
};

export const getGeo = (coords) => (dispatch) => {
  dispatch({
    type: UserActionTypes.GET_GEO,
    payload: coords,
  });
};

export const login = (data, t, successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USER_PENDING });

  axiosInstance
    .post(`/user/login`, data)
    .then((res) => {
      dispatch({
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: res.data,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: res.data.cartCount,
      });

      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.GET_USER_ERROR,
        payload: error.response.data,
      });

      toast.error(t(error.response.data.message));
    });
};

export const forgotPassword = (data, t, successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.FORGOT_PASSWORD.PENDING });

  axiosInstance
    .post(`/user/password/forgot`, data)
    .then((res) => {
      dispatch({
        type: UserActionTypes.FORGOT_PASSWORD.SUCCESS,
        payload: res.data,
      });

      toast.info(
        t("A password reset link has been sent to your email address")
      );

      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.FORGOT_PASSWORD.ERROR,
        payload: error.response.data,
      });

      toast.error(t(error.response.data.message));
    });
};

export const resetPassword = (data, t, successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.RESET_PASSWORD.PENDING });

  axiosInstance
    .post(`/user/password/reset`, data)
    .then((res) => {
      dispatch({
        type: UserActionTypes.RESET_PASSWORD.SUCCESS,
        payload: res.data,
      });

      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.RESET_PASSWORD.ERROR,
        payload: error.response.data,
      });

      toast.error(t(error.response.data.message));
    });
};

export const resetVerifyPassword =
  (data, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.RESET_VERIFY_PASSWORD.PENDING });

    axiosInstance
      .post(`/user/password/verify`, data)
      .then((res) => {
        dispatch({
          type: UserActionTypes.RESET_VERIFY_PASSWORD.SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.RESET_VERIFY_PASSWORD.ERROR,
          payload: error.response.data,
        });

        if (typeof errorCallback === "function") {
          errorCallback(error.response.data.message);
        }
      });
  };

export const loginViaOtp = (data, successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.LOGIN.USER_VIA_OTP_PENDING });

  axiosInstance
    .post(`/user/login/otp`, data)
    .then((res) => {
      dispatch({
        type: UserActionTypes.LOGIN.USER_VIA_OTP_SUCCESS,
        payload: res.data,
      });

      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.LOGIN.USER_VIA_OTP_ERROR,
        payload: error.response.data,
      });

      toast.error(error.response.data.message);
    });
};

export const validationCode =
  (login, code, successCallback, failCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.GET_USER_PENDING });

    axiosInstance
      .post(`/user/validation/${login}/${code}`, {})
      .then((res) => {
        dispatch({
          type: UserActionTypes.GET_USER_SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.GET_USER_ERROR,
          payload: error.response.data,
        });

        if (typeof failCallback === "function") {
          failCallback(error.response.data.message);
        }

        toast.error(error.response.data.message);
      });
  };

export const logout = (successCallback) => (dispatch) => {
  dispatch({ type: UserActionTypes.GET_USER_PENDING });

  axiosInstance
    .delete(`/user/logout`)
    .then((res) => {
      dispatch({
        type: UserActionTypes.GET_USER_SUCCESS,
        payload: null,
      });
      dispatch({
        type: CartActionTypes.GET_CART_COUNT_SUCCESS,
        payload: 0,
      });
      dispatch({
        type: CartActionTypes.GET_CART_SUCCESS,
        payload: [],
      });

      if (typeof successCallback === "function") {
        successCallback();
      }
    })
    .catch((error) => {
      dispatch({
        type: UserActionTypes.GET_USER_ERROR,
        payload: error.response.data,
      });
      toast.error(error.response.data);
    });
};

export const addMoney =
  (money = 0, credit = false) =>
  (dispatch) => {
    dispatch({ type: UserActionTypes.POST_MONEY_PENDING });

    axiosInstance
      .post(`/user/money`, {
        credit,
        money,
      })
      .then((res) =>
        dispatch({
          type: UserActionTypes.POST_MONEY_SUCCESS,
          payload: res.data,
        })
      )
      .catch((error) => {
        dispatch({
          type: UserActionTypes.POST_MONEY_ERROR,
          payload: error.response.data,
        });

        toast.error(error?.response?.data?.message);
      });
  };

export const sendMoney =
  (data, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.SEND_MONEY_PENDING });

    axiosInstance
      .post(`/user/money/send`, data)
      .then((res) => {
        dispatch({
          type: UserActionTypes.SEND_MONEY_SUCCESS,
          payload: res.data,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.SEND_MONEY_ERROR,
          payload: error.response.data,
        });

        if (typeof errorCallback === "function") {
          errorCallback();
        }
      });
  };

export const sendGift =
  (data, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.SEND_GIFT_PENDING });

    axiosInstance
      .post(`/user/gift/send`, data)
      .then((res) => {
        dispatch({ type: UserActionTypes.SEND_GIFT_SUCCESS });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.SEND_GIFT_ERROR,
          payload: error,
        });

        if (typeof errorCallback === "function") {
          errorCallback();
        }
      });
  };

export const register =
  (data, t, successCallback, errorCallback) => (dispatch) => {
    dispatch({ type: UserActionTypes.USER_REGISTRATION_PENDING });
    axiosInstance
      .post(`/user/registration`, data)
      .then((res) => {
        dispatch({
          type: UserActionTypes.USER_REGISTRATION_SUCCESS,
          payload: res.data,
        });
        dispatch({
          type: CartActionTypes.GET_CART_COUNT_SUCCESS,
          payload: res.data.cartCount,
        });

        if (typeof successCallback === "function") {
          successCallback();
        }
      })
      .catch((error) => {
        dispatch({
          type: UserActionTypes.USER_REGISTRATION_ERROR,
          payload: error.response.data,
        });
        toast.error(t(error.response.data.message));

        if (typeof errorCallback === "function") {
          errorCallback();
        }
      });
  };
