import React, { useState, useEffect } from "react";
import ShoppingCartOutlinedIcon from "@material-ui/icons/ShoppingCartOutlined";
import PersonOutline from "@material-ui/icons/PersonOutline";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import MenuIcon from "@mui/icons-material/Menu";
import CloseOutlinedIcon from "@mui/icons-material/CloseOutlined";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { useNavigate, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import MicIcon from "@mui/icons-material/Mic";
import SearchOutlinedIcon from "@material-ui/icons/SearchOutlined";
import {
  openSidebar,
  setB2BValue,
  getUnused,
} from "../../redux/actions-exporter";
import widgetHelper from "../../helpers/widget";
import "./header.css";
import Logo from "../../assets/images/logo.png";
import SearchResult from "../searchResult/SearchResult";

const Header = ({ searchText, setSearchText }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [subToggleMenu, setSubToggleMenu] = useState(false);
  const [notification, setNotification] = useState(false);
  const [isPageWithBusinessToggle, setIsPageWithBusinessToggle] =
    useState(false);

  const unused = useSelector((state) => state.order?.unused);

  const b2b = useSelector((state) => state.tabbing.b2b);
  useEffect(() => {
    !unused.length && dispatch(getUnused());
  }, []);
  useEffect(() => {
    const pathname = location.pathname;
    setIsPageWithBusinessToggle(
      pathname.includes("/club") ||
        pathname.includes("/checkout") ||
        pathname.includes("/payment") ||
        pathname.includes("/loadmoney") ||
        pathname.includes("/paymenthistory") ||
        pathname.includes("/checkout/pay") ||
        pathname.includes("/paymentcardhistory") ||
        pathname.includes("/cart") ||
        pathname.includes("/wallet") ||
        pathname.includes("/bookingcart") ||
        pathname.includes("/bookingcartdetail") ||
        pathname.includes("/bookingpayment") ||
        pathname.includes("/payments") ||
        pathname.includes("/prices")
    );
  }, [location]);

  const cartCount = useSelector((state) => state.cart.count);
  const user = useSelector((state) => state?.user?.user);

  const openSearch = () => {
    document.querySelector(".menuSearch").style.display = "none"
      ? "block"
      : "none";
  };
  const handlenotification = () => {
    if (!notification) {
      setNotification(true);
    } else {
      setNotification(false);
    }
  };
  const handleB2B = (event, newValue) => {
    newValue !== null && dispatch(setB2BValue(newValue));
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      navigate("/search");
    }
  };

  const renderButtonsMobile = (
    <>
      <Button
        sx={{
          borderColor: "var(--primary)",
          color: "var(--primary)",
          m: "0 20px 0 2px ",
        }}
        variant="outlined"
        onClick={() => window.open("https://sub.raayonit.co.il/")}
      >
        שוברי נייר
      </Button>
      <Button
        sx={{
          borderColor: "var(--primary)",
          color: "var(--primary)",
          m: "0 20px 0 2px ",
        }}
        variant="outlined"
        onClick={() =>
          window.open("https://cards.raayonit.co.il/extendVoucher")
        }
      >
        הארכת תוקף
      </Button>
      <Button
        sx={{
          orderColor: "var(--primary)",
          color: "var(--primary)",
          m: "0 20px 0 2px ",
        }}
        variant="outlined"
        onClick={() =>
          window.open(
            "https://sub.raayonit.co.il/Site/he/pages/inPage.asp?page=giftcard&action=checkbudget"
          )
        }
      >
        בירור יתרה
      </Button>
      {/*<Button*/}
      {/*  sx={{*/}
      {/*    background: "var(--primary)",*/}
      {/*    color: "white",*/}
      {/*    m: "0 20px 0 2px ",*/}
      {/*    lineHeight: "13px",*/}
      {/*    "&:hover": {*/}
      {/*      opacity: "0.5",*/}
      {/*      background: "var(--primary)",*/}
      {/*    },*/}
      {/*  }}*/}
      {/*  variant="outlined"*/}
      {/*  onClick={() => navigate("/allvendors")}*/}
      {/*>*/}
      {/*  הכוכבים שלנו*/}
      {/*</Button>*/}
    </>
  );

  const renderRightMenu = (
    <>
      {" "}
      <div
        className="mainheader__btn mainheader__btn--cart d-flex d-lg-none"
        onClick={() => {
          setSubToggleMenu((prev) => !prev);
        }}
      >
        {!subToggleMenu && <MenuIcon />}
        {subToggleMenu && <CloseOutlinedIcon />}
      </div>
      {user && (
        <>
          <Button
            className="dropBtn mainheader__btn mainheader__btn--cart"
            onClick={() => navigate("/userprofile")}
          >
            <PersonOutline />
          </Button>
          <div className="notificationBlock">
            <Button
              className="dropBtn mainheader__btn mainheader__btn--cart"
              onClick={handlenotification}
            >
              <NotificationsNoneIcon />
              <span className="subCount subCountRight">1</span>
            </Button>
            {notification && (
              <div className="notificationList">
                <div className="chatMainBox">
                  <div className="chatMainBox__inner">
                    <div className="chatMainBox__img">
                      <img
                        loading="lazy"
                        src={Logo}
                        alt=""
                        height={50}
                        width={50}
                        className="img-fluid"
                      />
                    </div>
                    <div className="chatMainBox__info">
                      <h4 className="chatMainBox__name">{t("chatbot")}</h4>
                      <p className="chatMainBox__text">
                        ברוכים הבאים לרעיונית! מועדון ההטבות המוביל בישראל
                      </p>
                    </div>
                  </div>
                  <span className="chatMainBox__time">4pm</span>
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {!user && (
        <Button
          onClick={() => widgetHelper.login(() => dispatch(openSidebar()))}
          className="loginBtn d-lg-block "
        >
          {!user ? t("login") : t("sign out")}
        </Button>
      )}
      <Button
        className="dropBtn mainheader__btn mainheader__btn--cart"
        onClick={() => {
          if (
            window.location.pathname === "/company" ||
            window.location.pathname === "/product" ||
            window.location.pathname === "/bookingcart" ||
            window.location.pathname === "/bookingcartdetail"
          )
            navigate("/bookingcart");
          else {
            if (!user) {
              widgetHelper.login(
                () => dispatch(openSidebar()),
                () => navigate("/cart")
              );
            } else {
              navigate("/cart");
            }
          }
        }}
      >
        <ShoppingCartOutlinedIcon />
        <span className="subCount subCountRight">{cartCount || 0}</span>
      </Button>
      <Button className="dropBtn mainheader__btn mainheader__btn--cart">
        <AccountBalanceWalletIcon
          onClick={() => {
            if (!user) {
              widgetHelper.login(
                () => dispatch(openSidebar()),
                () => navigate("/login")
              );
            } else {
              navigate("/wallet");
            }
          }}
        />
        <span className="subCount subCountRight">{unused?.length || 0}</span>
      </Button>
    </>
  );

  return (
    <div className="main">
      <div className="mainheader">
        <div className="container-fluid">
          <div
            className="row align-items-center"
            style={{ justifyContent: "space-between" }}
          >
            <div className="">
              <div className="headerLeft">
                <span
                  className="userBlock-img"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  <img
                    loading="lazy"
                    src={Logo}
                    alt="img"
                    className="img-fluid"
                  />
                </span>
                <Box sx={{ display: { xs: "flex", sm: "none" } }}>
                  <ToggleButtonGroup
                    size="small"
                    value={b2b}
                    exclusive
                    onChange={handleB2B}
                    sx={{
                      direction: "ltr",
                      width: "80px",
                      "&>*": { lineHeight: "12px" },
                    }}
                  >
                    <ToggleButton value={false}>לקוח פרטי</ToggleButton>
                    <ToggleButton value={true}>לקוח עסקי</ToggleButton>
                  </ToggleButtonGroup>
                </Box>

                <div className="MainMenu">
                  {!isPageWithBusinessToggle && (
                    <ToggleButtonGroup
                      size="small"
                      value={b2b}
                      exclusive
                      onChange={handleB2B}
                      sx={{
                        direction: "ltr",
                        mr: 2,
                        "&>*": {
                          lineHeight: "13px",
                          minWidth: "80px",
                        },
                      }}
                    >
                      <ToggleButton value={false}>לקוח פרטי</ToggleButton>
                      <ToggleButton value={true}>לקוח עסקי</ToggleButton>
                    </ToggleButtonGroup>
                  )}

                  <Button
                    sx={{
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                      m: "0 20px 0 2px ",
                      lineHeight: "13px",
                      minWidth: "120px",
                    }}
                    variant="outlined"
                    onClick={() => window.open("https://sub.raayonit.co.il/")}
                  >
                    שוברי נייר
                  </Button>
                  <Button
                    sx={{
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                      m: "0 2px",
                      lineHeight: "13px",
                      minWidth: "120px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      window.open("https://cards.raayonit.co.il/extendVoucher")
                    }
                  >
                    הארכת תוקף
                  </Button>
                  <Button
                    sx={{
                      borderColor: "var(--primary)",
                      color: "var(--primary)",
                      m: "0 2px",
                      lineHeight: "13px",
                      minWidth: "120px",
                    }}
                    variant="outlined"
                    onClick={() =>
                      window.open(
                        "https://sub.raayonit.co.il/Site/he/pages/inPage.asp?page=giftcard&action=checkbudget"
                      )
                    }
                  >
                    בירור יתרה
                  </Button>
                  {/*<Button*/}
                  {/*  sx={{*/}
                  {/*    background: "var(--primary)",*/}
                  {/*    color: "white",*/}
                  {/*    m: "0 2px",*/}
                  {/*    lineHeight: "13px",*/}
                  {/*    minWidth: "120px",*/}
                  {/*    "&:hover": {*/}
                  {/*      opacity: "0.5",*/}
                  {/*      background: "var(--primary)",*/}
                  {/*    },*/}
                  {/*  }}*/}
                  {/*  variant="outlined"*/}
                  {/*  onClick={() => navigate("/allvendors")}*/}
                  {/*>*/}
                  {/*  הכוכבים שלנו*/}
                  {/*</Button>*/}
                </div>
              </div>
            </div>

            <div className="text-right" style={{ display: "flex" }}>
              <form
                onSubmit={(e) => e.preventDefault()}
                className="search-container d-none d-lg-block"
              >
                <div className="search-container__btn">
                  <SearchOutlinedIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => navigate("search")}
                  />
                </div>
                <input
                  type="text"
                  id="search-bar"
                  placeholder={`${t("Search")}`}
                  className="search-container__input"
                  onChange={(e) => setSearchText(e.target.value)}
                  onClick={openSearch}
                  onKeyDown={handleKeyDown}
                  value={searchText}
                />
                <div className="mic-container__btn">
                  <MicIcon />
                </div>
              </form>
              <SearchResult
                searchText={searchText}
                // filterProducts={filterProducts}
                // filterStores={filterStores}
                // isMap={isMap}
                // mapSearch={mapSearch}
              />
              <div className="headerRight">{renderRightMenu}</div>
            </div>
            {/* Mobile */}
            <div className="col-12 d-flex d-lg-none">
              <div
                className={
                  subToggleMenu ? "mobileSearch activeSubMenu" : "mobileSearch "
                }
              >
                <div className="responsiveSubMenu">
                  <div className="hiddenBtns">{renderButtonsMobile}</div>
                </div>
              </div>
            </div>
            {/* Mobile */}
          </div>
        </div>
      </div>
      <div className="mobileHeader">
        <div
          className="mobileHeader__logo"
          onClick={() => {
            navigate("/");
          }}
        >
          <img loading="lazy" src={Logo} alt="img" className="img-fluid" />
        </div>
        <div className="mobileHeader__bottomRow">
          {renderRightMenu}
          <ToggleButtonGroup
            size="small"
            value={b2b}
            exclusive
            onChange={handleB2B}
            sx={{
              direction: "ltr",
              "&>*": { lineHeight: "12px" },
            }}
          >
            <ToggleButton value={false}>לקוח פרטי</ToggleButton>
            <ToggleButton value={true}>לקוח עסקי</ToggleButton>
          </ToggleButtonGroup>
        </div>

        {subToggleMenu && (
          <div className="responsiveSubMenu">
            <div className="hiddenBtns">{renderButtonsMobile}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
